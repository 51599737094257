import colors from '../colors';
import Spacings from '../Spacings';

interface NavigationTheme {
  topBar: {
    background: string;
  };
}

interface SimpleHeaderTheme {
  background: string;
}

interface AboutHeaderTheme {
  background: string;
}

interface HeaderCtaCardTheme {
  topCard: {
    background: string;
  };
}

interface ArticlePreviewCardTheme {
  background: string;
  metaColor: string;
  titleColor: string;
  hoverColor: string;
  categoryBackground: string;
  tagColor: string;
  paddingTextContainer: {
    default: string;
    xl: string;
  };
  tagPadding: string;
}

export interface ComponentsTheme {
  navigation: NavigationTheme;
  simpleHeader: SimpleHeaderTheme;
  aboutHeader: AboutHeaderTheme;
  headerCtaCard: HeaderCtaCardTheme;
  articlePreviewCard: ArticlePreviewCardTheme;
}

export const defaultComponentsTheme: ComponentsTheme = {
  navigation: {
    topBar: {
      background: colors.abnGradient,
    },
  },
  simpleHeader: {
    background: colors.abnGradient,
  },
  aboutHeader: {
    background: colors.abnGradient,
  },
  headerCtaCard: {
    topCard: {
      background: colors.abnGradientG400toG200,
    },
  },
  articlePreviewCard: {
    background: colors.white,
    metaColor: colors.abnMutedGreen400,
    titleColor: colors.abnBlack,
    hoverColor: colors.abnGreen300,
    categoryBackground: colors.abnMutedGreen50,
    tagColor: colors.abnMutedGreen300,
    paddingTextContainer: {
      default: Spacings.M,
      xl: `${Spacings.L} ${Spacings.XL}`,
    },
    tagPadding: Spacings.XS,
  },
};

export const bisComponentsTheme: ComponentsTheme = {
  navigation: {
    topBar: {
      background: colors.abnGreen500,
    },
  },
  simpleHeader: {
    background: colors.abnGreen500,
  },
  aboutHeader: {
    background: colors.abnGreen500,
  },
  headerCtaCard: {
    topCard: {
      background: colors.abnGreen500,
    },
  },
  articlePreviewCard: {
    background: colors.white,
    metaColor: colors.abnMutedGreen400,
    titleColor: colors.abnBlack,
    hoverColor: colors.abnGreen300,
    categoryBackground: colors.abnMutedGreen50,
    tagColor: colors.abnMutedGreen300,
    paddingTextContainer: {
      default: Spacings.M,
      xl: `${Spacings.L} 40px`,
    },
    tagPadding: Spacings.XS,
  },
};
