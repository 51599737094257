export interface RadiusesTheme {
  radiuses: {
    S: string;
    M: string;
    L: string;
    XL: string;
  };
}

export const defaultRadiuses: RadiusesTheme = {
  radiuses: {
    S: '2px',
    M: '4px',
    L: '8px',
    XL: '50%',
  },
};

export const bisRadiuses: RadiusesTheme = {
  radiuses: {
    S: '4px',
    M: '4px',
    L: '4px',
    XL: '50%',
  },
};
