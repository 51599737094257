import colors from '../colors';

type RegularFont = 'Roboto, sans-serif' | 'ABNAMRO Sans Regular, Arial';
type CondensedFont =
  | 'Roboto Condensed, sans-serif'
  | 'ABNAMRO Sans Regular, Arial';
type SemiboldFont = 'Roboto, sans-serif' | 'ABNAMRO Sans SemiBold, Arial';
type TextSize = { S: string; L: string };

interface TextTheme {
  lineHeight?: TextSize;
  fontSize?: TextSize;
  fontWeight?: '400' | '500';
}

export interface FontsTheme {
  fontRegular: RegularFont;
  fontCondensed: CondensedFont;
  fontSemibold: SemiboldFont;
  textColor: string;
  h1: TextTheme;
  h2: TextTheme;
  h3: TextTheme;
  h4: TextTheme;
  label: TextTheme;
  blockquote: TextTheme;
}

export const defaultFontsTheme: FontsTheme = {
  fontRegular: 'Roboto, sans-serif',
  fontCondensed: 'Roboto Condensed, sans-serif',
  fontSemibold: 'Roboto, sans-serif',
  textColor: colors.abnBlack,
  h1: {
    lineHeight: {
      S: '48px',
      L: '64px',
    },
    fontSize: {
      S: '40px',
      L: '56px',
    },
  },
  h2: {
    lineHeight: {
      S: '32px',
      L: '40px',
    },
    fontSize: {
      S: '28px',
      L: '32px',
    },
  },
  h3: {
    lineHeight: {
      S: '24px',
      L: '32px',
    },
    fontSize: {
      S: '20px',
      L: '24px',
    },
  },
  h4: {
    lineHeight: {
      S: '24px',
      L: '24px',
    },
    fontSize: {
      S: '18px',
      L: '20px',
    },
  },
  label: {
    fontWeight: '500',
  },
  blockquote: {
    lineHeight: {
      S: '24px',
      L: '32px',
    },
    fontSize: {
      S: '20px',
      L: '24px',
    },
  },
};

export const bisFontsTheme: FontsTheme = {
  fontRegular: 'ABNAMRO Sans Regular, Arial',
  fontCondensed: 'ABNAMRO Sans Regular, Arial',
  fontSemibold: 'ABNAMRO Sans SemiBold, Arial',
  textColor: colors.abnBlack,
  h1: {
    lineHeight: {
      S: '48px',
      L: '60px',
    },
    fontSize: {
      S: '36px',
      L: '48px',
    },
  },
  h2: {
    lineHeight: {
      S: '30px',
      L: '36px',
    },
    fontSize: {
      S: '24px',
      L: '28px',
    },
  },
  h3: {
    lineHeight: {
      S: '22px',
      L: '30px',
    },
    fontSize: {
      S: '18px',
      L: '22px',
    },
  },
  h4: {
    lineHeight: {
      S: '24px',
      L: '24px',
    },
    fontSize: {
      S: '18px',
      L: '20px',
    },
  },
  label: {
    fontWeight: '400',
  },
  blockquote: {
    lineHeight: {
      S: '22px',
      L: '30px',
    },
    fontSize: {
      S: '18px',
      L: '22px',
    },
  },
};
