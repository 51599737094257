import { createGlobalStyle } from 'styled-components';

import '@fontsource/roboto';
import '@fontsource/roboto-condensed';

import check from '../images/check.svg';

import mq from './breakpoints';
import colors from './colors';
import { H3Css } from './generic';
import Spacings from './Spacings';

import '@fontsource/roboto/500.css';
import './fonts.css';

export default createGlobalStyle`
  :root, html, body, #root {
    color: ${({ theme }) => theme.textColor};
    font-family: ${({ theme }) => theme.fontRegular};
    background: ${({ theme }) => theme.colors.abnNeutral50};
  }

  body {
    font-family: ${({ theme }) => theme.fontCondensed};
    background: ${({ theme }) => theme.colors.abnNeutral50};
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    .aab-cookie-consent {
      font-family: ${({ theme }) => theme.fontCondensed};
      
      .header h2 {
        font-family: ${({ theme }) => theme.fontCondensed};
      }

      .text p {
        font-family: ${({ theme }) => theme.fontRegular};
        font-size: 16px;
      }
    }
  }
  
  b {
    weight: 700;
  }
  
  h3 {
    ${H3Css}
  }

  a {
    color: currentColor;
  }
  
  p:empty {
    display: none;
  }
  
  ul {
    list-style-image: url(${check});
    padding-left: 21px;
  }

  li {
    padding-left: 8px;
    margin-bottom: 8px;

    p {
      margin: 0
    }
  }

  .articleQuote {
    width: 100%;
    font-family: ${({ theme }) => theme.fontCondensed};
    font-size: 32px;
    line-height: 1;
    color: ${colors.black};
    margin-top: 48px;

    ${mq.from.breakpoint.M`
      font-size: 48px;
      margin-top: ${Spacings.XXXL};
      position: relative;

      > span {
        position: absolute;
        left: -32px;
      }
    `}
  }
`;
