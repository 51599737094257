import { css } from 'styled-components';

export const BASE_FONT_SIZE = 16;

// these sizes are arbitrary, and you can set them to whatever you wish
export const breakpoints = {
  XL: 1320,
  L: 992,
  M: 768,
  S: 0,
};

// use em in breakpoints to work properly cross-browser and support users
// changing their browsers font-size: https://zellwk.com/blog/media-query-units/
export const emBreakpoints = Object.assign(
  Object.keys(breakpoints).reduce(
    (newEmBreakpoints, breakPoint) => ({
      ...newEmBreakpoints,
      [breakPoint]: breakpoints[breakPoint] / BASE_FONT_SIZE,
    }),
    {}
  )
);

// iterate through the sizes and create a media template
const fromBreakpoint = Object.assign(
  Object.keys(breakpoints).reduce(
    (mqObject, breakPoint) => ({
      ...mqObject,
      [breakPoint]: (...args) => {
        const [first, ...rest] = args;
        return css`
          @media (min-width: ${emBreakpoints[breakPoint]}em) {
            ${css(first, ...rest)}
          }
        `;
      },
    }),
    {}
  )
);

const fromPx =
  (px) =>
  (...args) => {
    const [first, ...rest] = args;
    return css`
      @media (min-width: ${px}px) {
        ${css(first, ...rest)}
      }
    `;
  };

const toBreakpoint = Object.assign(
  Object.keys(breakpoints).reduce(
    (mqObject, breakPoint) => ({
      ...mqObject,
      [breakPoint]: (...args) => {
        const [first, ...rest] = args;
        return css`
          @media (max-width: ${emBreakpoints[breakPoint]}em) {
            ${css(first, ...rest)}
          }
        `;
      },
    }),
    {}
  )
);

const toPx =
  (px) =>
  (...args) => {
    const [first, ...rest] = args;
    return css`
      @media (max-width: ${px}px) {
        ${css(first, ...rest)}
      }
    `;
  };

const mediaQueries = {
  from: {
    px: fromPx,
    breakpoint: fromBreakpoint,
  },
  to: {
    px: toPx,
    breakpoint: toBreakpoint,
  },
};

export default mediaQueries;
