import colors from '../colors';
import Spacings from '../Spacings';

type ButtonTheme = {
  activeBackgroundColor?: string;
  activeBorderColor?: string;
  backgroundColor?: string;
  borderColor?: string;
  color: string;
  activeColor?: string;
  hoverColor?: string;
  hoverBackgroundColor?: string;
  hoverBorderColor?: string;
  focusBorderColor?: string;
  focusColor?: string;
  fontFamily?: string;
  fontWeight?: string;
  padding?: string;
};

export interface PrimaryButtonTheme {
  primaryButton: {
    default: ButtonTheme;
    highlight: ButtonTheme;
    muted: ButtonTheme;
    choiceButton: ButtonTheme;
  };
}

export interface SecondaryButtonTheme {
  secondaryButton: {
    default: ButtonTheme;
    highlight: ButtonTheme;
  };
}

export const primaryButtonDefault: PrimaryButtonTheme = {
  primaryButton: {
    default: {
      activeBackgroundColor: colors.abnGreen400,
      activeBorderColor: colors.abnGreen400,
      backgroundColor: colors.abnGreen300,
      borderColor: 'transparent',
      color: colors.white,
      hoverBackgroundColor: colors.abnLinkGreenB,
      hoverBorderColor: 'transparent',
      focusBorderColor: colors.abnDarkBlue01,
      focusColor: colors.white,
      fontFamily: 'Roboto, sans-serif',
      fontWeight: '400',
      padding: `12px ${Spacings.S}`,
    },
    highlight: {
      activeBackgroundColor: colors.abnYellow400,
      activeBorderColor: colors.abnYellow400,
      backgroundColor: colors.abnYellow,
      borderColor: 'transparent',
      color: colors.black,
      hoverBackgroundColor: colors.abnZachtgeel,
      hoverBorderColor: 'transparent',
      focusBorderColor: colors.abnDarkBlue01,
      focusColor: colors.black,
      fontFamily: 'Roboto, sans-serif',
      fontWeight: '400',
      padding: `12px ${Spacings.S}`,
    },
    muted: {
      activeBackgroundColor: colors.abnMutedGreen55,
      activeBorderColor: colors.abnMutedGreen,
      backgroundColor: colors.abnGreen50,
      borderColor: colors.abnMutedGreen,
      color: colors.abnGreen300,
      hoverBackgroundColor: colors.abnMutedGreen50,
      hoverBorderColor: colors.abnGreen200,
      focusBorderColor: 'transparent',
      focusColor: colors.abnGreen300,
      fontFamily: 'Roboto, sans-serif',
      fontWeight: '400',
      padding: `12px ${Spacings.S}`,
    },
    choiceButton: {
      activeBackgroundColor: colors.abnGreen65,
      activeBorderColor: colors.abnGreen100,
      backgroundColor: colors.abnGreen50,
      borderColor: 'transparent',
      color: colors.abnNeutral670,
      hoverBackgroundColor: colors.abnGreen55,
      hoverBorderColor: colors.abnGreen75,
      focusBorderColor: colors.abnDarkBlue01,
      focusColor: colors.abnNeutral670,
      fontFamily: 'Roboto, sans-serif',
      fontWeight: '400',
      padding: `12px ${Spacings.S}`,
    },
  },
};

export const primaryButtonBis: PrimaryButtonTheme = {
  primaryButton: {
    default: {
      ...primaryButtonDefault.primaryButton.default,
      backgroundColor: colors.abnGreen500,
      hoverBackgroundColor: colors.abnGreen600,
      activeBackgroundColor: colors.abnGreen670,
      fontFamily: 'ABNAMRO Sans SemiBold, Arial',
      fontWeight: '500',
      padding: `10px ${Spacings.S}`,
    },
    highlight: {
      ...primaryButtonDefault.primaryButton.highlight,
      hoverBackgroundColor: colors.abnYellow400,
      activeBackgroundColor: colors.abnYellow500,
      fontFamily: 'ABNAMRO Sans SemiBold, Arial',
      fontWeight: '500',
      padding: `10px ${Spacings.S}`,
    },
    muted: {
      ...primaryButtonDefault.primaryButton.muted,
      backgroundColor: colors.white,
      hoverBackgroundColor: colors.abnNeutral55,
      activeBackgroundColor: colors.abnNeutralsN65,
      borderColor: 'transparent',
      hoverBorderColor: 'transparent',
      focusBorderColor: 'transparent',
      focusColor: colors.abnGreen500,
      fontFamily: 'ABNAMRO Sans SemiBold, Arial',
      fontWeight: '500',
      padding: `10px ${Spacings.S}`,
    },
    choiceButton: {
      ...primaryButtonDefault.primaryButton.choiceButton,
      fontFamily: 'ABNAMRO Sans SemiBold, Arial',
      fontWeight: '500',
      padding: `10px ${Spacings.S}`,
    },
  },
};

export const secondaryButtonDefault: SecondaryButtonTheme = {
  secondaryButton: {
    default: {
      color: colors.abnGreen300,
      hoverColor: colors.abnGreen200,
      activeColor: colors.abnGreen300,
      fontFamily: 'Roboto',
    },
    highlight: {
      color: colors.white,
      hoverColor: colors.white,
      activeColor: colors.white,
      fontFamily: 'Roboto',
    },
  },
};

export const secondaryButtonBis: SecondaryButtonTheme = {
  secondaryButton: {
    ...secondaryButtonDefault.secondaryButton,
    default: {
      ...secondaryButtonDefault.secondaryButton.default,
      color: colors.abnGreen500,
      activeColor: colors.abnGreen670,
      hoverColor: colors.abnGreen600,
      fontFamily: 'ABNAMRO Sans SemiBold, Arial',
    },
    highlight: {
      ...secondaryButtonDefault.secondaryButton.highlight,
      fontFamily: 'ABNAMRO Sans SemiBold, Arial',
    },
  },
};
