import React from 'react';
import { GatsbyBrowser } from 'gatsby';

import Redirect from './src/components/utils/Redirect';
import { StyleSheetManager } from './src/utils/providers/StyleSheetManager';
import ThemeProvider from './src/utils/providers/ThemeProvider';

import GlobalStyles from './src/styles/index';

// When to use wrapRootElement or wrapPageElement?
// https://markoskon.com/wrap-root-element-vs-wrap-page-element/
// wrapPageElement renders every time the page changes—wrapRootElement does not
// because wrapRootElement doesn’t render when the page changes, it’s a
// good fit for context providers that don’t need the page, like theme or
// global application state providers

// Layout releated stuff should go inside wrapPageElement
// https://github.com/gatsbyjs/gatsby/issues/24341#issuecomment-636031414
// NOTE: GlobalStyles from styled-components v5 > NEEDS to be in wrapPage for SSR
export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = (
  { element, props } // TODO: Can global styles be moved to wrapRootElement?
) => (
  <ThemeProvider>
    <Redirect location={props.location}>
      <GlobalStyles />
      <StyleSheetManager>{element}</StyleSheetManager>
    </Redirect>
  </ThemeProvider>
);

// When the URL changes and the route is the same, disable the scroll to top.
// This is usefull for cases where the the same page is updating filters or tags in the URL.
export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = (
  parameters
) => {
  const { routerProps, prevRouterProps } = parameters;

  const isSameRoute =
    prevRouterProps?.location.pathname === routerProps.location.pathname;

  return !isSameRoute;
};
