export default {
  black: '#000000',
  abnBlack: '#222222',
  abnNeutral10: '#7D7D7D',
  abnNeutral50: '#F9F9F9',
  abnNeutral55: '#E9E9E9',
  abnNeutral200: '#959595',
  abnNeutral300: '#808080',
  abnNeutral400: '#666666',
  abnNeutral500: '#4D4D4D',
  abnNeutral670: '#222222',
  white: '#FFFFFF',
  abnNeutrals75: '#CCCCCC',
  abnGradient: 'linear-gradient(90deg, #005E5D 0%, #00857A 100%)',
  abnLinkGreenB: '#00857a',
  abnYellow: '#FFD200',
  abnYellow400: '#F3C000',
  abnYellow500: '#E5B400',
  disabledButtonGrey: '#DEDEDE',
  abnButtonGreen: '#004343',
  abnZachtgeel: '#FFEA8F',
  abnIceGrey: '#D8F0F0',
  abnGreen100: '#37ABA1',
  abnGreen200: '#00857A',
  abnGreen300: '#00716B',
  abnGreen400: '#005E5D',
  abnGreen500: '#004C4C',
  abnGreen600: '#003737',
  abnGreen670: '#002929',
  abnGreen700: '#001E1E',
  abnGreen50: '#EDF7F7',
  abnGreen55: '#D8F0EF',
  abnGreen65: '#ADE0DF',
  abnGreen75: '#86D1CD',
  abnMutedGreen: '#C0D1D0',
  abnMutedGreen50: '#F2F7F7',
  abnMutedGreen55: '#E9F0F0',
  abnMutedGreen65: '#D3E0E0',
  abnMutedGreen100: '#91ABA9',
  abnMutedGreen200: '#668582',
  abnMutedGreen300: '#4F706F',
  abnMutedGreen400: '#3C5E5E',
  abnMutedGreen500: '#2D4D4D',
  abnNeutralsN65: '#DEDEDE',
  abnNeutralsN100: '#B3B3B3',
  abnDarkBlue01: '#0044AA',
  abnBlue01: '#005CDE',
  abnPrivacy400: '#6927A3',
  abnInfo400: '#0070E9',
  abnPositive400: '#528217',
  abnNegative400: '#D21B02',
  abnLightBlue: '#5E9ED6',
  abnGradientG400toG200: 'linear-gradient(133deg, #005E5D 0%, #00857A 100%)',
};
