enum WizardOptionCondition {
  DEFAULT = 'default',
  SKIP_FORM = 'skip-form',
  SKIP_STEPS = 'skip-steps',
}

enum WizardResultState {
  DEFAULT = 'default',
  EMPTY = 'empty',
}

export { WizardOptionCondition, WizardResultState };
